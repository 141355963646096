<script>
import { HELPER_VARIANTS, NOTIFICATION_COLOR, NOTIFICATION_TYPE } from '@emobg/web-components';
import { NOTIFICATION_ICONS } from '@/components/NotificationList/constants/notification.const';
import { webAppColors } from '@/constants/themes';

export default {
  name: 'NotificationRibbon',
  props: {
    type: {
      type: String,
      default: '',
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
    autoCloseDelay: {
      type: Number,
      default: 0,
    },
    isClosable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentType() {
      return NOTIFICATION_TYPE[this.type] ? this.type : NOTIFICATION_TYPE.info;
    },
    notificationColors() {
      return {
        ...NOTIFICATION_COLOR,
        info: webAppColors.informative,
        success: webAppColors.brand,
      };
    },
    backgroundColor() {
      return `${this.notificationColors[this.currentType]}${HELPER_VARIANTS.light}`;
    },
    iconType() {
      return NOTIFICATION_ICONS[this.currentType];
    },
    iconColor() {
      const iconColor = {
        warning: `${this.notificationColors[this.currentType]}${HELPER_VARIANTS.dark}`,
        default: `${this.notificationColors[this.currentType]}`,
      };
      return iconColor[this.currentType] || iconColor.default;
    },
  },
  mounted() {
    if (this.autoClose) {
      setTimeout(() => {
        this.$emit('on:close-ribbon');
      }, this.autoCloseDelay);
    }
  },
};
</script>

<template>
  <div
    data-test-id="notification"
    :class="`NotificationRibbon w-100 emobg-background-color-${backgroundColor}`"
  >
    <div class="NotificationRibbon__body">
      <div class="NotificationRibbon__body__message d-flex align-items-center emobg-color-ink py-3">
        <ui-icon
          :icon="iconType"
          :color="iconColor"
          :size="SIZES.small"
          :data-test-id="`${type}-icon`"
          class="NotificationRibbon__body__icon ml-3"
        />
        <div class="NotificationRibbon__body__slot ml-2">
          <p
            :data-test-id="`${type}-message`"
            class="NotificationRibbon__paragraph m-0 mt-1"
          >
            <slot />
          </p>
        </div>
        <div
          v-if="isClosable"
          :data-test-id="`${type}-action-text`"
          class="cursor-pointer ml-2 mt-1 emobg-font-weight-bold"
          @click="$emit('on:close-ribbon')"
        >
          <slot name="dismissActionLabel" />
        </div>
      </div>
    </div>
  </div>
</template>
