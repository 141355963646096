<script>
import { computed, ref, watch } from 'vue';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useRoute } from 'vue-router/composables';
import NotificationRibbon from './NotificationRibbon';
export default {
  components: {
    NotificationRibbon,
  },
  setup() {
    const router = useRoute();
    const { currentActiveNotification, onActiveNotificationClose, removeAllNotifications } = useNotifications();
    const activeNotification = ref(currentActiveNotification);
    const isNotificationVisible = computed(() => !!activeNotification.value?.text);
    const isNotificationClosable = computed(() => activeNotification.value?.isClosable);
    function closeNotification() {
      const { action, isClosable } = activeNotification.value;
      if (action) {
        action();
      }
      if (isClosable) {
        onActiveNotificationClose({ ...activeNotification.value });
      }
    }

    watch(
      () => router.name,
      () => {
        removeAllNotifications();
      },
      {
        immediate: true,
      },
    );
    return {
      activeNotification,
      isNotificationVisible,
      onActiveNotificationClose,
      closeNotification,
      isNotificationClosable,
    };
  },
};

</script>
<template>
  <div
    v-if="isNotificationVisible"
    class="NotificationListManager w-100 position-fixed"
  >
    <NotificationRibbon
      :type="activeNotification.type"
      :auto-close="!!activeNotification.delayToClose"
      :auto-close-delay="activeNotification.delayToClose"
      :is-closable="isNotificationClosable"
      @on:close-ribbon="closeNotification"
    >
      {{ activeNotification.text }}
      <template #dismissActionLabel>
        {{ activeNotification.textAction ? activeNotification.textAction : $t('modal.common.ok') }}
      </template>
    </NotificationRibbon>
  </div>
</template>
<style lang="scss">
  .NotificationListManager {
    z-index: 99;
  }
</style>
