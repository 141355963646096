import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import values from 'lodash/values';
import find from 'lodash/find';
import get from 'lodash/get';
import {
  COUNTRIES_ISO_CODES,
  LOG_TYPE,
  STORE_REQUEST_STATUS,
  logger,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import Api from '@/vue/api/backoffice';

export const NAMESPACE = 'DrivingLicenseRoadblock';

const STEPS = {
  selectCountryDrivingLicense: 'StepOne',
  uploadDrivingLicense: 'StepTwo',
  pendingValidationDrivingLicense: 'StepThree',
};

const DRIVING_LICENSE_PROVIDERS = {
  manual: 'manual',
  electronicId: 'electronic-id',
};

const INIT_STATE = () => ({
  list: {
    countries: [],
    drivingLicenseProviders: [],
  },
  data: {
    countrySelected: null,
    dvla: null,
    dvlaRequired: false, // Set this field with the CS Operator config of the user
    step: STEPS.selectCountryDrivingLicense,
    drivingLicenseFinishedWithBadge: false,
    drivingLicenseCreated: null,
    drivingLicenseNotCreated: '',
    dvlaValid: false,
    drivingLicenseImages: {
      front: null,
      back: null,
      selfie: null,
    },
  },
  status: STORE_REQUEST_STATUS.idle,
  error: null,
});

const initialState = INIT_STATE();

export const cleanDvlaNumber = number => {
  if (number && isString(number)) {
    const reg = /[a-zA-Z0-9]/;
    return number.split('').filter(i => !!reg.exec(i) === true).join('').trim();
  }

  return '';
};

export const GETTERS = {
  COUNTRY_SELECTED_NEEDS_DVLA: 'isDvlaNeededInCountrySelected',
  DVLA_IS_REQUIRED_BY_CS_OPERATOR_AND_COUNTRY: 'isDvlaRequiredByCsOperatorAndCountry',
  CLEANNED_DVLA: 'cleannedDvla',
  SELECT_COUNTRY_DL_BUTTON_DISABLED: 'isSelectCountryDLButtonDisabled',
  UPLOAD_DL_BUTTON_DISABLED: 'isUploadDrivingLicenseButtonDisabled',
};

const getters = {
  [GETTERS.COUNTRY_SELECTED_NEEDS_DVLA](state) {
    const countrySelected = get(state, 'data.countrySelected');
    const providers = get(state, 'list.drivingLicenseProviders');

    if (!countrySelected || get(providers, 'length') === 0) {
      return false;
    }

    const provider = providers.find(i => i.internal_name === DRIVING_LICENSE_PROVIDERS.manual);

    const country = find(get(provider, 'countries'), ['code', countrySelected]);

    return get(country, 'dlva');
  },

  [GETTERS.CLEANNED_DVLA](state) {
    const dvla = get(state, 'data.dvla');

    return cleanDvlaNumber(dvla);
  },

  [GETTERS.DVLA_IS_REQUIRED_BY_CS_OPERATOR_AND_COUNTRY](state, roadBlockGetters) {
    return get(state, 'data.dvlaRequired') && get(roadBlockGetters, GETTERS.COUNTRY_SELECTED_NEEDS_DVLA);
  },

  [GETTERS.SELECT_COUNTRY_DL_BUTTON_DISABLED](state, roadBlockGetters) {
    const isDvlaRequired = get(roadBlockGetters, GETTERS.DVLA_IS_REQUIRED_BY_CS_OPERATOR_AND_COUNTRY);
    // When DVLA is not required, it should be TRUE
    // to set button as enabled
    const isDvlaValidWhenIsRequired = isDvlaRequired ? state.data.dvlaValid : true;

    return !(isDvlaValidWhenIsRequired && state.data.countrySelected);
  },

  [GETTERS.UPLOAD_DL_BUTTON_DISABLED](state) {
    const front = get(state, 'data.drivingLicenseImages.front');
    const back = get(state, 'data.drivingLicenseImages.back');
    const selfie = get(state, 'data.drivingLicenseImages.selfie');

    return !!(front && back && selfie) === false;
  },
};

export const MUTATIONS = {
  SET_COUNTRIES: 'setCountries',
  SET_DRIVING_LICENSE_PROVIDERS: 'setDrivingLicenseProvider',
  SET_COUNTRY_SELECTED: 'setCountrySelected',
  SET_DVLA: 'setDvla',
  SET_DVLA_REQUIRED: 'setDvlaRequired',
  SET_DRIVING_LICENSE_FINISHED_WITH_BADGE: 'setDrivingLicenseFinishedWithBadge',
  SET_DRIVING_LICENSE_CREATED: 'setDrivingLicenseCreated',
  SET_DRIVING_LICENSE_NOT_CREATED: 'setDrivingLicenseNotCreated',
  SET_STEP: 'setStep',
  RESET_DATA: 'resetStateData',
  RESET_DRIVING_LICENCE_CREATED: 'resetDrivingLicenseCreated',
  RESET_DRIVING_LICENCE_NOT_CREATED: 'resetDrivingLicenseNotCreated',
  SET_DVLA_VALID: 'setDvlaValid',
  SET_FRONT_DL: 'setFrontDrivingLicense',
  SET_BACK_DL: 'setBackDrivingLicense',
  SET_SELFIE_DL: 'setSelfieDrivingLicense',
  SET_STATUS: 'setStatus',
  SET_ERROR_MESSAGE: 'setErrorMessage',
};

const mutations = {
  [MUTATIONS.SET_COUNTRIES](state, list = []) {
    state.list.countries = list;
  },

  [MUTATIONS.SET_DRIVING_LICENSE_PROVIDERS](state, providers = {}) {
    state.list.drivingLicenseProviders = providers;
  },

  [MUTATIONS.SET_COUNTRY_SELECTED](state, country) {
    state.data.countrySelected = country;
  },

  [MUTATIONS.SET_DVLA](state, dvla) {
    state.data.dvla = dvla;
  },

  [MUTATIONS.SET_DVLA_REQUIRED](state, required = false) {
    state.data.dvlaRequired = isBoolean(required) ? required : false;
  },
  [MUTATIONS.SET_DRIVING_LICENSE_FINISHED_WITH_BADGE](state, value) {
    state.data.drivingLicenseFinishedWithBadge = isBoolean(value) ? value : false;
  },
  [MUTATIONS.SET_DRIVING_LICENSE_CREATED](state, value) {
    state.data.drivingLicenseCreated = value;
  },
  [MUTATIONS.SET_DRIVING_LICENSE_NOT_CREATED](state, value) {
    state.data.drivingLicenseNotCreated = value;
  },
  [MUTATIONS.SET_STEP](state, step) {
    const found = values(STEPS).find(i => i === step);

    if (!found) {
      return;
    }

    state.data.step = step;
  },

  [MUTATIONS.SET_DVLA_VALID](state, value) {
    state.data.dvlaValid = isBoolean(value) ? value : false;
  },

  [MUTATIONS.RESET_DATA](state) {
    state.data = { ...INIT_STATE().data };
  },

  [MUTATIONS.RESET_DRIVING_LICENCE_CREATED](state) {
    state.data.drivingLicenseCreated = null;
  },

  [MUTATIONS.RESET_DRIVING_LICENCE_NOT_CREATED](state) {
    state.data.drivingLicenseNotCreated = '';
  },

  [MUTATIONS.SET_FRONT_DL](state, mediaBase64) {
    state.data.drivingLicenseImages.front = mediaBase64;
  },

  [MUTATIONS.SET_BACK_DL](state, mediaBase64) {
    state.data.drivingLicenseImages.back = mediaBase64;
  },

  [MUTATIONS.SET_SELFIE_DL](state, mediaBase64) {
    state.data.drivingLicenseImages.selfie = mediaBase64;
  },

  [MUTATIONS.SET_STATUS](state, payload) {
    if (Object.values(STORE_REQUEST_STATUS).find(i => i === payload)) {
      state.status = payload;
    }
  },

  [MUTATIONS.SET_ERROR_MESSAGE](state, message) {
    state.error = message;
  },
};

export const ACTIONS = {
  FETCH_COUNTRIES: 'fetchCountries',
  FETCH_DRIVING_LICENSE_PROVIDERS: 'fetchDrivingLicenseProviders',
  GO_STEP_SELECT_COUNTRY_DRIVING_LICENSE: 'goStepSelectCountryDrivingLicense',
  GO_STEP_UPLOAD_DRIVING_LICENSE: 'goStepUploadDrivingLicense',
  GO_STEP_PENDING_DL_VALIDATION: 'goStepPendingDrivingLicenseValidation',
  CREATE_DRIVING_LICENSE: 'createDrivingLicense',
  SET_STATUS_AND_ERROR_MESSAGE: 'setStatusAndErrorMessage',
};

const actions = {
  async [ACTIONS.FETCH_COUNTRIES]({ commit, state }, force = false) {
    try {
      const countriesIsEmpty = get(state, 'list.countries.length', 0) === 0;

      // When countries array is not empty and force is false
      // we could avoid an unnecessary Api call
      if (!countriesIsEmpty && !force) {
        return;
      }

      const { data: countries } = await Api.app.getCountries();

      commit(MUTATIONS.SET_COUNTRIES, countries);
    } catch (error) {
      logger.message(error.message, LOG_TYPE.error);
    }
  },

  async [ACTIONS.FETCH_DRIVING_LICENSE_PROVIDERS]({ commit, state }, force = false) {
    try {
      const providersIsEmpty = get(state, 'list.drivingLicenseProviders.length', 0) === 0;

      // When countries array is not empty and force is false
      // we could avoid an unnecessary Api call
      if (!providersIsEmpty && !force) {
        return;
      }

      const { data: { data: providers } } = await Api.app.getDocumentValidationProviders();

      commit(MUTATIONS.SET_DRIVING_LICENSE_PROVIDERS, providers);
    } catch (error) {
      logger.message(error.message, LOG_TYPE.error);
    }
  },

  [ACTIONS.GO_STEP_SELECT_COUNTRY_DRIVING_LICENSE]({ commit }) {
    commit(MUTATIONS.SET_STEP, STEPS.selectCountryDrivingLicense);
  },

  [ACTIONS.GO_STEP_UPLOAD_DRIVING_LICENSE]({ commit }) {
    commit(MUTATIONS.SET_STEP, STEPS.uploadDrivingLicense);
  },

  [ACTIONS.GO_STEP_PENDING_DL_VALIDATION]({ commit }) {
    commit(MUTATIONS.SET_STEP, STEPS.pendingValidationDrivingLicense);
  },

  [ACTIONS.CREATE_DRIVING_LICENSE]({ commit, state, getters: roadBlockGetters }, payload) {
    // Declaring default values
    const defaultErrors = { notCompleted: '' };
    const defaultData = { userUuid: '' };

    // Merge payload
    const errors = { ...defaultErrors, ...payload.errors };
    const data = { ...defaultData, ...payload.data };
    // Are steps completed?
    const firstStepIsNotCompleted
      = roadBlockGetters[GETTERS.SELECT_COUNTRY_DL_BUTTON_DISABLED];

    const secondStepIsNotCompleted
      = roadBlockGetters[GETTERS.UPLOAD_DL_BUTTON_DISABLED];

    if (firstStepIsNotCompleted || secondStepIsNotCompleted) {
      throw new Error(errors.notCompleted);
    }

    commit(MUTATIONS.SET_STATUS, STORE_REQUEST_STATUS.loading);

    const front = get(state, 'data.drivingLicenseImages.front');
    const back = get(state, 'data.drivingLicenseImages.back');
    const selfie = get(state, 'data.drivingLicenseImages.selfie');
    const countryCode = get(state, 'data.countrySelected');
    const isUkSelected = countryCode === COUNTRIES_ISO_CODES.great_britain;
    const cleannedDvla = getters[GETTERS.CLEANNED_DVLA];

    const params = {
      userUuid: data.userUuid,
      licenseFront: front.replace(/^.*?base64,/g, ''),
      licenseBack: back.replace(/^.*?base64,/g, ''),
      selfieWithId: selfie.replace(/^.*?base64,/g, ''),
      ...(isUkSelected ? { dvla: cleannedDvla || null } : { country_code: countryCode }),
    };

    return external.userCompany.postUploadDocFiles(params, data.userUuid, isUkSelected ? 'v1' : 'v2')
      .then(response => {
        commit(MUTATIONS.SET_STATUS, STORE_REQUEST_STATUS.loaded);

        return response;
      }).catch(error => {
        throw new Error(get(error, 'response.data.message', 'Error!'));
      });
  },

  [ACTIONS.SET_STATUS_AND_ERROR_MESSAGE]({ commit }, message) {
    commit(MUTATIONS.SET_ERROR_MESSAGE, message);

    if (message) {
      commit(MUTATIONS.SET_STATUS, STORE_REQUEST_STATUS.error);
      return;
    }

    commit(MUTATIONS.SET_STATUS, STORE_REQUEST_STATUS.idle);
  },
};

export const store = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
