<template>
  <div class="Logo">
    <ui-image
      :alt="$t('common.logo')"
      :src="logoUrl"
      :width="LOGO_SIZE.width"
      :height="LOGO_SIZE.height"
    />
  </div>
</template>

<script>
import includes from 'lodash/includes';
import { ASSETS_CATEGORY, LOGOS_KEYNAMES } from '@/constants/assetsRepository';
import { getThemedAssetByCategoryAndKey } from '@/utils/assetsRepository';
import { LOGO_SIZE } from '@/components/Logo/Logo.const';

export default {
  name: 'Logo',
  props: {
    variant: {
      type: String,
      default: LOGOS_KEYNAMES.PRIMARY_LOGO,
    },
  },
  computed: {
    logoUrl() {
      const logoKeyName = includes([LOGOS_KEYNAMES.PRIMARY_LOGO, LOGOS_KEYNAMES.CONTRAST_LOGO], this.variant)
        ? this.variant
        : LOGOS_KEYNAMES.PRIMARY_LOGO;
      return getThemedAssetByCategoryAndKey(ASSETS_CATEGORY.LOGO, logoKeyName);
    },
  },
  created() {
    this.LOGO_SIZE = LOGO_SIZE;
  },
};
</script>
