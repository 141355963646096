<script>
import { COLORS, ICONS } from '@emobg/web-components';
import { webAppColors } from '@/constants/themes';
import ALERT_TYPES from './alertTypes';

const ALERT_ICONS = {
  [ALERT_TYPES.warning]: ICONS.alertFull,
  [ALERT_TYPES.danger]: ICONS.alertFull,
  [ALERT_TYPES.success]: ICONS.success,
  [ALERT_TYPES.informative]: ICONS.infoFull,
};

const ALERT_COLORS = {
  [ALERT_TYPES.warning]: COLORS.warning,
  [ALERT_TYPES.danger]: COLORS.danger,
  [ALERT_TYPES.success]: COLORS.success,
  [ALERT_TYPES.informative]: webAppColors.informative,
};

export default {
  name: 'AlertComponent',

  props: {
    type: {
      type: String,
      default: ALERT_TYPES.warning,
    },
  },

  created() {
    this.icon = ALERT_ICONS[this.type];
    this.color = ALERT_COLORS[this.type];
  },
};
</script>

<template>
  <ui-alert
    v-if="color"
    :color="color"
    :icon="icon"
  >
    <slot />
  </ui-alert>
</template>
