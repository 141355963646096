<template>
  <Transition :name="transition">
    <aside
      v-if="component"
      class="MuiFullScreen"
      v-bind="attrs"
    >
      <Component
        :is="component"
        v-bind="props"
        v-on="listeners"
      />
    </aside>
  </Transition>
</template>

<script>

export default {
  name: 'MuiFullScreenComponent',

  inheritAttrs: false,

  props: {
    transition: {
      type: String,
      default: 'fade',
    },
  },

  data() {
    return {
      fullScreen: this.$fullScreen,
    };
  },

  computed: {
    component() {
      return this.fullScreen && this.fullScreen.getComponent();
    },

    props() {
      return this.component && this.fullScreen.getProps();
    },

    listeners() {
      return this.component && this.fullScreen.getListeners();
    },

    attrs() {
      return this.$attrs;
    },
  },
};

</script>
