import isPlainObject from 'lodash/isPlainObject';

export class FullScreenManager {
  constructor() {
    this.reset();
  }

  /**
   * Show the component in a full screen wrapper
   * @param {object} component - Vue component to show
   * @param {object} config - Object configuration to bind the props and listeners of the component
   */
  show(component, config = {}) {
    if (!isPlainObject(component)) {
      throw new Error('component is required');
    }

    // Default values are empty objects because sometimes
    // we will want to spread the object to maintain some
    // properties and override others.
    const { props = {}, listeners = {} } = isPlainObject(config) ? config : {};

    this.component = component;
    this.props = props;
    this.listeners = listeners;
  }

  /**
   * Close the full screen wrapper
   */
  close() {
    this.reset();
  }

  /**
   * Get the component showed
   * @returns {object|null}
   */
  getComponent() {
    return this.component;
  }

  /**
   * Set component to show
   * @param {object} component - If the parameter is not an
   * object or a false value, it will not be set
   */
  setComponent(component) {
    this.component = isPlainObject(component) ? component : undefined;
  }

  /**
   *
   * @returns {object}
   */
  getProps() {
    return this.props;
  }

  /**
   * Set the new props to be passed to the component showed
   * @param {object} props - If the parameter is not an object or a false value, it will not be set
   */
  setProps(props = {}) {
    this.props = isPlainObject(props) ? props : {};
  }

  getListeners() {
    return this.listeners;
  }

  /**
   * Set the new listeners to be passed to the component showed
   * @param {object} listeners - If the parameter is not an
   * object or a false value, it will not be set
   */
  setListeners(listeners = {}) {
    this.listeners = isPlainObject(listeners) ? listeners : {};
  }

  reset() {
    this.component = null;
    this.props = {};
    this.listeners = {};
  }
}

export const FullScreenManagerInstance = new FullScreenManager();
