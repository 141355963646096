import MuiFullScreen from './MuiFullScreenComponent';
import { FullScreenManagerInstance } from './FullScreenManager';

export default {
  install: Vue => {
    Vue.mixin({
      beforeCreate() {
        this.$fullScreen = FullScreenManagerInstance;
      },
    });

    Vue.component('MuiFullScreen', MuiFullScreen);
  },
};
